@font-face {
	font-family: dalafloda;
	src: url(https://assets.dear-ux.com/fonts/DalaFloda-Bold-App.ttf) format("truetype");
}

@font-face {
	font-family: spacemono;
	src: url(https://assets.dear-ux.com/fonts/SpaceMono-Regular.ttf) format("truetype");
}

@font-face {
	font-family: roboto;
	src: url(https://assets.dear-ux.com/fonts/Roboto.ttf) format("truetype");
}

@font-face {
	font-family: montserratLight;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-Light.ttf) format("truetype");
}

@font-face {
	font-family: montserrat;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
	font-family: montserratRegular;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
	font-family: montserratMedium;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
	font-family: montserratBold;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
	font-family: montserratSemiBold;
	src: url(https://assets.dear-ux.com/fonts/Montserrat-SemiBold.ttf) format("truetype");
}


.App {
	text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
